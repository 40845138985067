<template>
  <div class="app-container">
    <div id="app1">
      <div class="content">
        <div class="detail">
          <div class="box">
            <span class="label">客户名称：</span>
            <div class="text">{{ cname }}</div>
          </div>
          <div class="box">
            <span class="label">门店名称：</span>
            <div class="text">{{ shopName }}</div>
          </div>
          <div class="box">
            <span class="label">端口号：</span>
            <div class="text">{{ boxPort }}</div>
          </div>
          <div class="box">
            <span class="label">SolutionID：</span>
            <div class="text">{{ productId }}</div>
          </div>
          <div class="box">
            <span class="label">盒子Mac-ID：</span>
            <div class="text">{{ boxMacId }}</div>
          </div>
          <div class="box">
            <span class="label">状态：</span>
            <div class="text">{{ status }}</div>
          </div>
        </div>
        <div class="title">已绑定NVR列表</div>
        <el-button class="newDevice" type="primary" @click="openDialog(2)">手动添加</el-button>
        <el-table :data="bindTableData" class="bindtable" row-key="pkid" v-loading="bindLoading" show-overflow-tooltip>
          <el-table-column type="index" :index="indexTable" min-width="30" label="序号"></el-table-column>
          <el-table-column prop="macId" align="left" label="MAC-ID"></el-table-column>
          <el-table-column prop="name" align="left" label="设备名称"></el-table-column>
          <el-table-column prop="ip" align="left" label="IP地址"></el-table-column>
          <el-table-column prop="channelCnt" align="left" show-overflow-tooltip label="通道数">
            <template slot-scope="scope">
              <span>{{scope.row.channelCnt||'--'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="vendor" align="left" show-overflow-tooltip label="厂商"></el-table-column>
          <el-table-column prop="model" align="left" show-overflow-tooltip label="型号"></el-table-column>
          <el-table-column prop="bindTime" align="left" show-overflow-tooltip label="操作时间"></el-table-column>
          <el-table-column label="操作" fixed="right" align="center" width="200">
            <template slot-scope="scope">
              <el-button
                @click="linkSetting('retail-traffic-device-nvrManage-setting', scope.row)"
                type="text"
                size="small"
              >
                设置
              </el-button>
              <el-button @click="unbind(scope.row)" class="unbind" type="text" size="small">解绑</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-button class="newDevice" type="primary" @click="getNvrList(true)">扫描新的设备</el-button>
        <el-table
          :data="nobindtable"
          row-key="pkid"
          class="nobindtable"
          v-loading="unbindLoading"
          ref="multipleTable"
          :header-cell-class-name="tableClass"
          show-overflow-tooltip
        >
          <el-table-column type="index" :index="noindexTable" align="right" show-overflow-tooltip label="序号"></el-table-column>
          <el-table-column prop="macId" align="right" show-overflow-tooltip label="MacID"></el-table-column>
          <el-table-column prop="online" align="center" label="在线状态" width="90">
            <template slot-scope="scope">
              {{ scope.row.online === true ? '在线' : '离线' }}
            </template>
          </el-table-column>
          <el-table-column prop="vendor" align="right" show-overflow-tooltip label="厂商"></el-table-column>
          <el-table-column prop="model" align="right" show-overflow-tooltip label="型号"></el-table-column>
          <el-table-column prop="ip" align="right" show-overflow-tooltip label="IP地址"></el-table-column>
          <el-table-column prop="bindStatus" align="center" label="绑定状态" width="130">
            <template slot-scope="scope">
              <span type="text" size="small">{{ scope.row.bindStatus ? '已绑定' : '未绑定' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="boxMac" label="绑定盒子MacID">
            <template slot-scope="scope">
              {{ scope.row.boxMac || '--' }}
            </template>
          </el-table-column>
          <el-table-column prop="	snapshot" align="center" label="截图">
            <template slot-scope="scope">
              <img :src="scope.row.snapshot" @click="enlargeImg(scope.row.snapshot)" class="screenshot" />
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" align="center" width="200">
            <template slot-scope="scope">
              <el-button @click="openDialog(1, scope.row)" class="unbind" type="text" size="small">绑定</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="allSelect">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next"
            :total="total"
          ></el-pagination>
        </div>
        <el-dialog :visible.sync="imgDialog" class="imgDialog" width="800px" title="预览" :before-close="handleClose">
          <img :src="bigImgUrl" class="bigImgUrl" />
        </el-dialog>
      </div>
    </div>
    <el-dialog
      :title="type === 1 ? '绑定NVR' : '添加NVR'"
      width="400px"
      :visible.sync="bindDialogVisible"
      class="bindDialog"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="设备名称" prop="name" v-if="type === 1">
          <el-input v-model.trim="form.name" auto-complete="off" maxlength="50" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="设备名称" prop="name" v-else>
          <el-input v-model.trim="form.name" auto-complete="off" maxlength="50" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="MacID" prop="macId" v-if="type === 2">
          <el-input v-model.trim="form.macId" auto-complete="off" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="IP地址" prop="ip" v-if="type === 2">
          <el-input v-model.trim="form.ip" auto-complete="off" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="设备账号" prop="uname">
          <el-input v-model="form.uname" auto-complete="off" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="设备密码" prop="pwd">
          <el-input v-model="form.pwd" auto-complete="off" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="厂商" prop="vendor">
          <el-select v-model="form.vendor" placeholder="请选择">
            <el-option v-for="item in vedorOpt" :key="item" :label="item" :value="item" ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="bindDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="bindBox">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getScanNvrs, bindToBox, unbindFromBox } from 'src/api/nvr'

export default {
  data() {
    return {
      id: '',
      productId: '',
      cname: '',
      shopName: '',
      boxPort: '',
      boxMacId: '',
      bindTableData: [],
      newTable: [],
      status: '',
      select: false,
      imgDialog: false,
      bigImgUrl: '',
      currentPage: 1,
      pageSize: 5,
      total: 0,
      bindLoading: false,
      unbindLoading: false,

      bindDialogVisible: false,
      form: {
        name: '',
        macId: '',
        ip: '',
        uname: '',
        pwd: '',
        vendor: undefined,
      },
      rules: {
        macId: [{ required: true, message: '请填写后点击确认', trigger: 'blur' }],
        ip: [{ required: true, message: '请填写后点击确认', trigger: 'blur' }],
        name: [{ required: true, message: '请填写后点击确认', trigger: 'blur' }],
        uname: [{ required: true, message: '请填写后点击确认', trigger: 'blur' }],
        pwd: [{ required: true, message: '请填写后点击确认', trigger: 'blur' }],
        vendor: [{ required: true, message: '请选择后点击确认', trigger: 'change' }],
      },
      vedorOpt:['DA_HUA','JVS','HIKVISION'],
      type: 1,
    }
  },
  watch: {},
  computed: {
    nobindtable() {
      return this.newTable.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    },
  },
  mounted() {
    this.id = this.$route.query.boxId
    this.productId = this.$route.query.productId
    this.overviewInit()
  },
  methods: {
    linkSetting(name, row) {
      this.$router.push({
        name,
        query: {
          productId: this.productId,
          macId: row.macId
        },
      })
    },
    openDialog(type, row) {
      this.type = type

      this.bindDialogVisible = true
      this.$nextTick(() => {
        this.resetForm()
        if (type === 1) {
          this.form.macId = row.macId
          this.form.ip = row.ip
          this.form.vedor = row.vedor
        }else{
          this.form.macId = ''
          this.form.ip = ''
        }
      })
    },
    indexTable(index) {
      if (typeof index === 'undefined') {
        return 0
      }
      return index + 1
    },
    noindexTable(index) {
      if (typeof index === 'undefined'){
        return 0
      } 
      return (this.currentPage - 1) * this.pageSize + index + 1
    },
    tableClass({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return 'hideLabel'
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    getNvrList(msg) {
      this.bindLoading = true
      this.unbindLoading = true
      getScanNvrs({
        boxId: this.id,
        productId: this.productId,
      })
        .then((res) => {
          console.log(res)
          if (res && res.status === 0) {
            this.productId = res.data.productId
            this.cname = res.data.cname
            this.shopName = res.data.shopName
            this.boxPort = res.data.boxPort
            this.boxMacId = res.data.macId
            this.status = res.data.onlineStatus ? '在线' : '离线'
            const hasBind = res.data.hasBind ? res.data.hasBind : []
            for (const v of hasBind) {
              v.boxMAC = v.boxMAC || ''
            }
            this.bindTableData = hasBind
            this.newTable = res.data.noBind
            for (const v of this.newTable) {
              v.boxMAC = v.boxMAC || ''
            }
            this.total = this.newTable.length
            if (!msg) return
            this.$message({
              message: '扫描成功',
              type: 'success',
            })
          }
        })
        .finally(() => {
          this.bindLoading = false
          this.unbindLoading = false
        })
    },
    resetForm() {
      this.$refs.ruleForm.resetFields()
    },
    bindBox(row) {
      // this.$confirm('确定要绑定到该盒子上吗?', '绑定', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      // }).then(() => {
      //   this.bindCamera(row)
      // })
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.bindCamera(row)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    bindCamera(row) {
      let data = {
        boxId: this.id,
        macId: this.form.macId,
        ip: this.form.ip,
        name: this.form.name,
        uname: this.form.uname,
        pwd: this.form.pwd,
        vendor: this.form.vendor,
      }
      bindToBox(data).then((res) => {
        if (res && res.status === 0) {
          this.$alert('已绑定NVR列表', '绑定提示', {
            confirmButtonText: '确定',
          })
          this.bindDialogVisible = false
          this.getNvrList()
        }
      })
    },
    overviewInit() {
      //页面初始化
      this.getNvrList()
    },

    unbind(row) {
      console.log(row)
      this.$confirm(`是否解绑${row.name}`, '提示').then(() => {
        this.unbindFromBox(row)
      })
    },
    unbindFromBox(row) {
      let data = {
        macId: row.macId,
      }
      unbindFromBox(data).then((res) => {
        if (res && res.status === 0) {
          this.$alert('解绑成功', '解绑提示', {
            confirmButtonText: '确定',
          })
          this.getNvrList()
        }
      })
    },
    handleClose(done) {
      done()
    },
    enlargeImg(url) {
      this.imgDialog = true
      this.bigImgUrl = url
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables.less';

* {
  box-sizing: border-box;
}

.containerX .contentX {
  padding-left: 0;
  padding-right: 0;
}

.titletop {
  width: 100%;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid #d1d1d1;
  padding: 0px 20px;
}

.titletop span {
  font-size: 14px;
  color: #5c76cc;
}

.titletop span i {
  color: #333;
}

.content {
  padding: 0 20px 40px;
}
.content .detail {
  padding: 10px 0;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d1d1d1;
  flex-wrap: wrap;
}
.content .detail .box {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 8px;
}

.content .title {
  font-size: 18px;
  margin-top: 20px;
}

.content .title span {
  color: #5c76cc;
}

.bindtable,
.nobindtable,
.allSelect {
  margin-bottom: 20px;
}
.allSelect {
  display: flex;
  justify-content: right;
}

.el-button {
  font-size: 12px;
}

.hideLabel .el-checkbox,
.hideLabel div {
  display: none !important;
}
.newDevice {
  margin-top: 20px;
}
.unbind::before {
  content: '';
  height: 5px;
  border-left: 1px solid @linkColor;
  margin-right: 12px;
}

.screenshot {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.imgDialog .el-dialog__header {
  padding: 0;
  border: none;
  height: 0px;
}

/* 关闭按钮 */
.imgDialog .el-dialog__headerbtn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #c2c2c2;
  right: 10px;
  top: 10px;
}

/* 关闭图标 */
.imgDialog .el-dialog__headerbtn .el-icon-close {
  color: #333;
}

.imgDialog .el-dialog__body {
  padding: 0;
}

.imgDialog .bigImgUrl {
  width: 760px;
  height: 600px;
  vertical-align: top;
}
.el-message-box__content {
  padding-left: 36px;
}

.word-string {
  width: 300px;
}
.bindDialog {
  /deep/.el-form-item__content {
    width: 230px;
  }
  .el-select {
    width: 100%;
  }
}
</style>
